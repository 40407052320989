// store2.js
import { configureStore } from '@reduxjs/toolkit';

const storeReduxToolKit = configureStore({
  reducer: {
    // Add other reducers for store2
  },
});

export default storeReduxToolKit;
