import React from 'react';
import { apiBaseUrl } from '../api';

const basedPostUrlRequestLogedIn = async (url, body) => {
  const newApiBaseUrl = apiBaseUrl();
  try {
    const response = await fetch(newApiBaseUrl + url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(body), // data can be `string` or {object}!

      credentials: 'include',
      headers: {
        'Content-Type': 'application/transit+json',
      },
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

export default basedPostUrlRequestLogedIn;
